import styled  from "styled-components";
import { BoundedContent } from "../../BoundedContent";
import charles from "./charles-zhang-profile.jpg";

const Container = styled(BoundedContent)`
${props => props.theme.boundedContent}
align-items: center;
display: flex;
flex-direction: column;
gap: ${props => props.theme.spacing.medium};
`

const Heading = styled.div`
font-size: ${props => props.theme.spacing.xlarge};
font-weight: 700;
`;

const Profile = styled.img`
border-radius: 0.2rem;
overflow: hidden;

width: 100%;
max-width: 300px;
margin-left: auto;
margin-right: auto;
${props => props.theme.breakpoints.medium}{
  width: 25%;
}
`;

const Row = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
align-items: flex-start;
gap: ${props => props.theme.spacing.medium};
`

const Content = styled.div`
display: flex;
flex-direction: column;
gap: ${props => props.theme.spacing.small};

width: 100%;
${props => props.theme.breakpoints.medium}{
  width: 70%;
}
`

const Name = styled.span`
font-size: ${props => props.theme.spacing.small};
color: ${props => props.theme.palette.l1};
`

const Title = styled.span``;

const Description = styled.span`
line-height: ${props => props.theme.spacing.medium};
`


export function Founder () {
  return (
    <Container>
      <Heading>Methodox Team</Heading>
      <Row>
      <Profile
        alt="Charle's profile"
        src={charles}
      />
        <Content>
          <Name>Charles Zhang</Name>
          <Title>Founder, Lead Developer</Title>
          <Description>
            Gamer, writer and world builder. Graduated from the University of Toronto with a degree in Computer Engineering. Previously worked at Baycrest Hospital and Blackbird Interactive, former developer on the Minecraft Legends game, as well as a Financial Analyst at the Ontario Teachers' Pension Plan. Extensive knowledge about the gaming and software development industry, with unique insight in full-stack development and expertise in desktop programming.
            <br/>
            Contact: Charles.Zhang@Methodox.io
          </Description>
        </Content>
      </Row>
    </Container>
  );
}



