import styled  from "styled-components";
import { useEffect, useState, ReactNode } from "react";

export type IQuestion = {
  content: ReactNode;
  title: string; 
}

type IProps = IQuestion & { 
  isOpen: boolean;
  onClick: () => void;
}

const ANIMATION_DELAY = 100;
const ANIMATION_DURATION = 500;

const Title = styled.button <{isOpen: boolean }>`
cursor: pointer;

width: 100%;
text-align: left;
background: transparent;

font-size: ${props => props.theme.spacing.small};
font-weight:  ${props => !props.isOpen ? "inherit" : 700};

border: none;
padding-left: 0;
padding-right: 0;
padding-top: ${props => props.theme.spacing.small};
border-top: 1px solid #777;
color: ${props => !props.isOpen ? "inherit" : props.theme.palette.l2};

&:hover {
  color: ${props => props.theme.palette.l2};
}
`

const Content = styled.div<{ isHeightZero: boolean; isVisible: boolean }>`
display: ${props => props.isVisible ? "block" :"none"};
overflow: hidden;
width: 100%;
max-height: ${props => props.isHeightZero ? "0" :"50vh"};
transition: max-height ${ANIMATION_DURATION / 1000}s;
`

export const Question = ({title, content, onClick, isOpen }: IProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHeightZero, setIsHeightZero] = useState(false);

  useEffect(() => {
    if(isOpen) {
      const timeout = setTimeout(() => setIsHeightZero(false), ANIMATION_DELAY);
      return () => {
        clearTimeout(timeout);
      }
    } else {
      setIsHeightZero(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if(isOpen) {
      setIsVisible(true);
    } else {
      const timeout = setTimeout(() => setIsVisible(false), ANIMATION_DURATION + ANIMATION_DELAY);
      return () => {
        clearTimeout(timeout);
      }
    }
  }, [isOpen]);

  return <>
    <Title isOpen={isOpen} onClick={onClick}>{title}</Title>
    <Content 
      isHeightZero={isHeightZero}
      isVisible={isVisible}
    >{content}</Content>
  </>
}


