export type IBlog = {
    name: string;
    description: string;
    image: string;
    title: string;
    filePath: string;
}

export const BLOGS: IBlog[] = [
    { 
        name: "how-to-choose", 
        title: "How to Choose the Right Low-Code, No-Code, or Process Automation Platform",
        image: `${process.env.PUBLIC_URL}/images/posting1.png`,
        description: "Choosing the right low-code, no-code, or process automation platform involves evaluating scalability, integration, customization, and user-friendliness. Zora by Methodox Technologies excels with its modular architecture, seamless C# and Python integration, and intuitive interface, supporting both workflow automation and everyday computational tasks with minimal setup and high manageability.",
        filePath: `${process.env.PUBLIC_URL}/posts/how-to-choose.md`,
    },
    { 
        name: "hard-to-make", 
        title: "Why Is a General Purpose Visual Programming Platform Hard to Make?",
        image: `${process.env.PUBLIC_URL}/images/posting2.png`,
        description: "Creating a general-purpose visual programming platform is challenging due to the need for balancing accessibility, versatility, and scalability while overcoming technical hurdles like complex logic representation and performance optimization. It's more of an art than engineering problem. This article explores these challenges and highlights the gap between domain-specific visual tools and general-purpose text-based languages.",
        filePath: `${process.env.PUBLIC_URL}/posts/hard-to-make.md`,
    },
    { 
        name: "workflow-sharing", 
        title: "The Problem and Challenges with Sharing in Program Development and The Visual Paradigm",
        image: `${process.env.PUBLIC_URL}/images/posting3.png`,
        description: "Efficient sharing in visual programming is crucial for rapid iteration and creative problem-solving, yet existing platforms often struggle with reusability and accessibility due to technical dependencies and organizational challenges. Zora addresses these issues by offering a flexible, cross-platform solution that promotes modularity and seamless collaboration.",
        filePath: `${process.env.PUBLIC_URL}/posts/workflow-sharing.md`,
    },
    { 
        name: "subgraphs", 
        title: "Subgraphs: Essential Building Blocks for Visual Programming Platforms",
        image: `${process.env.PUBLIC_URL}/images/posting4.png`,
        description: "Subgraphs are a vital ingredient in visual programming platforms, enabling the breakdown of complex tasks into manageable components, enhancing abstraction, readability, and collaboration. Zora supports both document referencing and embedded subgraphs, providing flexibility for different project needs.",
        filePath: `${process.env.PUBLIC_URL}/posts/subgraphs.md`,
    },
    {
        name: "dsl", 
        title: "Unlocking the Power of Node-Based Interfaces for DSL Implementation",
        image: `${process.env.PUBLIC_URL}/images/posting5-dsl.png`,
        description: "When considering the implementation of Domain-Specific Languages (DSL) and the challenges posed by traditional microservices architecture, Zora emerges as a powerful and extensible visual programming platform. Integrating C# and Python, Zora provides a low-cost, low-overhead solution for automating business processes, streamlining development, and enhancing data access with its user-friendly node-based interface.",
        filePath: `${process.env.PUBLIC_URL}/posts/dsl.md`,
    },
    { 
        name: "education", 
        title: "The Power of Visual Programming in Education: Going Beyond the Basics with Zora",
        image: `${process.env.PUBLIC_URL}/images/posting6.png`,
        description: "Zora by Methodox Technologies is a powerful visual programming platform that grows with learners, offering an easy-to-use interface for beginners while providing advanced features for more experienced coders. It's a tool that takes students from their first steps in programming to real-world applications, making coding accessible and engaging at every level.",
        filePath: `${process.env.PUBLIC_URL}/posts/education.md`,
    }
]
