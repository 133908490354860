export const theme = {
  breakpoints: {
    phone: "@media only screen and (max-width: 600px)",
    tablet: "@media only screen and (min-width: 600px)",
    medium: "@media only screen and (min-width: 768px)",
    desktop: "@media only screen and (min-width: 992px)",
    extraLarge: "@media only screen and (min-width: 1200px)",
  },
  spacing: {
    xsmall: "0.5rem",
    small: "1rem",
    medium: "1.5rem",
    large: "2rem",
    xlarge: "3rem",
  },
  palette: {
    l1: "#3C0BA9", 
    l2: "#930FA8",
    l3: "#8B10C6",
    l4: "#D02480",
    l5: "#E95E3C"
  }
}

export type ITheme = typeof theme;
