import styled  from "styled-components";
import { useCallback, useState } from "react";
import { BoundedContent } from "../../BoundedContent";
import { Question } from "./Question";
import { QUESTIONS } from "./questions";

const Container = styled(BoundedContent)`
${props => props.theme.boundedContent}
align-items: center;
display: flex;
flex-direction: column;
gap: ${props => props.theme.spacing.medium};
`

const Heading = styled.div`
font-size: ${props => props.theme.spacing.large};
font-weight: 700;
`;

export function FAQ () {
  const [openIndex, setOpenIndex] = useState(-1);
  const onClick = useCallback((index: number) => setOpenIndex(prev => prev === index ? -1 : index), []);

  return (
    <Container>
      <Heading>FAQ</Heading>
      {QUESTIONS.map(({ title, content }, index) =>
      <Question
        isOpen={openIndex === index}
        onClick={() => onClick(index)}
        title={title}
        content={content}
      />)}
    </Container>
  );
}


