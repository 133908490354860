import styled from "styled-components";

export const BoundedContent = styled.div`
    margin: 0 auto;
    max-width: min(100vw, 992px);
    padding: ${props => props.theme.spacing.large} ${props => props.theme.spacing.medium};

    ${props => props.theme.breakpoints.desktop} {
      padding: ${props => props.theme.spacing.xlarge} 0;
    }
`
