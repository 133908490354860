import styled  from "styled-components";
import { BoundedContent } from "../../BoundedContent";
import { Blog } from "./Blog";
import { BLOGS } from "../../Blog/blogs";

const Container = styled(BoundedContent)`
${props => props.theme.boundedContent}
align-items: center;
display: flex;
flex-direction: column;
gap: ${props => props.theme.spacing.medium};
`

const Heading = styled.h2`
font-size: ${props => props.theme.spacing.xlarge};
font-weight: 700;
margin: 0;
`;

const Row = styled.div`
display: grid;
gap: ${props=>props.theme.spacing.medium};
grid-template-columns: repeat(1, 1fr);
${props => props.theme.breakpoints.medium} {
    grid-template-columns: repeat(2, 1fr);
}
${props => props.theme.breakpoints.desktop} {
    grid-template-columns: repeat(3, 1fr);
}
`


export function Blogs () {

  return (
    <Container>
      <Heading>Blogs</Heading>
        <Row>
            {BLOGS.map(blog => <Blog key={blog.name} {...blog} />)}
        </Row>
    </Container>
  );
}



