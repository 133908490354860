import styled, { ThemeProvider } from "styled-components";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {  ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { useQueryParam, StringParam, QueryParamProvider } from "use-query-params";
import { theme } from "./theme";
import { Blog } from "./Blog/Blog";
import { Career } from "./Career/Career";
import { Home } from "./Home/Home";

const Container = styled.main`
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
`

function Main () {
  const [jobId] = useQueryParam("jobId", StringParam);
  const [blogId] = useQueryParam("blogId", StringParam);

  if(jobId) return <Career jobId={jobId} />
  if(blogId) return <Blog blogId={blogId} />
  return <Home />;
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <BrowserRouter>
          <QueryParamProvider
            adapter={ReactRouter6Adapter}
          >
            <Routes>
              <Route path="/" element={<Main/>}/>
            </Routes>
            
          </QueryParamProvider>
        </BrowserRouter>
      </Container>
    </ThemeProvider>
  );
}

export default App;
