import { IQuestion } from "./Question";

export const QUESTIONS: IQuestion[] = [
  {
    title:"Is Zora free to use?",
    content:"Yes! Zora offers Community version and Pro version, where Pro version offers more features related to scalability and production environments. Despite being very easy to use, Zora is still a technical tool. Having a welcoming community is crucial in knowledge sharing the ecosystem's longevity, so we will always be very supportive for free and non-commercial uses.",
  },
  {
    title:"Will the source codes be available?",
    content:"All source codes of the core engine and related infrastructures will be released on Github when release goes stable and when Methodox becomes profitable, whichever happens first.",
  },
  {
    title:"When can I try it?",
    content:<>We will host an online meeting and release the demo build on August 30th 10AM EST. The meeting will also announce the future development plan. Everyone is welcome to join. If you miss this, you can still join our Alpha Test, which takes place in late Sep 2024 - follow our LinkedIn account or send an email directly to contact@methodox.io for details.<br/>Detailed information below:<br/>Methodox - Zora Visual Computing: Big Announcement🐉<br/>Friday, August 30 · 10:00 - 11:00am<br/>Time zone: America/Toronto<br/>Google Meet joining info<br/>Video call link: https://meet.google.com/adh-dfgn-rrp<br/>Or dial:(CA) +1 437-781-4585 PIN: 590 307 959 2435#<br/>More phone numbers: https://tel.meet/adh-dfgn-rrp?pin=5903079592435</>,
  },
  {
    title:"Will Linux/Mac be supported?",
    content:"All three major platforms (Windows, Mac, Linux) will be supported in the first public release available in first quarter in 2025. Web platform will also be supported some time latter in 2025.",
  },
  {
    title:"Why is serialization (saving & loading) not available in Sep-Nov 2024 alpha test build?",
    content:"Serialization is not as straightforward as it appears because of the capability for Zora to support extensions.",
  },
  {
    title:"Will Zora support loops/if-else and other program flow control constructs?",
    content:"Future versions of Zora will have full procedural contexts but our main goal is to support those constructs and similar functionalities without having to break the functional paradigm.",
  },
  {
    title:"Is Methodx/Zora somehow related to AI?",
    content:"Yes. Zora exposes AI tools to its users (including traditional machine learning, GenAI, and LLM) and Methodox uses AI for various purposes. We also have plans for some original AI applications. More details latter!",
  },
  {
    title:"Is Methodx/Zora related to cryptocurrency and blockchain?",
    content:"As a computing platform, Zora will expose functions for users to easily experiment with and deploy blockchain technology.",
  },
  {
    title:"How extensible is the platform?",
    content:"Zora is built with extensibility in mind and at its core. We are very proud to claim that Methodox Zora is the most easily extensible no-code/low-code platform out there on the market! Integration with existing IT infrastructure are generally effortless and hassle-free.",
  },
  {
    title:"What programming skill level is Zora designed for?",
    content:"Beginner, intermediate and advanced - you are all taken care of! Zora is a unique platform enabling collaboration among all those roles, while each person with different skill level might use Zora in slightly different ways.",
  },
  {
    title:"Will the cloud version cost money to use?",
    content:"Yes. There is an infrastructure cost with cloud use and it's something we cannot offer for free; However, subscription charge will be minimal and there will be free trials for the online version.",
  }
]
