import { ReactNode } from "react";
import { useScrollHeight } from "./useScrollHeight";
import styled from "styled-components";

const Container = styled.div<{ $opacity: number }>`
opacity: ${props => props.$opacity};
`

export function VisibleContainer({ children, className, fullVisibleHeightPercentage = 1 }: {children: ReactNode, className?: string, fullVisibleHeightPercentage?: number }) {
  const { containerRef, heightPercentage } = useScrollHeight(fullVisibleHeightPercentage)
  return (
    <Container className={className} ref={ref => containerRef.current = ref!} $opacity={heightPercentage}>
      {children}
    </Container>

  );
}

