import { useCallback, useEffect, useRef, useState } from "react";

export function useScrollHeight(fullVisibleHeightPercentage = 1) {
  const containerRef = useRef<HTMLDivElement>();
  const [heightPercentage, setHeightPercentage] = useState(0);

  const listener = useCallback(() => {
    if(!containerRef.current) return;

    const windowHeight = window.innerHeight;
    const elementTop = containerRef.current.getBoundingClientRect().top;
    const newOpacity = (windowHeight - elementTop)  / (windowHeight * fullVisibleHeightPercentage);
    setHeightPercentage(Math.max(0, Math.min(1, newOpacity)));
  }, [fullVisibleHeightPercentage]);

  useEffect(() => {
    listener();

    window.addEventListener("scroll", listener, { passive: true });
    return () => {
      window.removeEventListener("scroll", listener);
    }
  }, [containerRef, listener]);

  return { containerRef, heightPercentage }
}
