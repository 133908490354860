import ReactMarkdown from 'react-markdown'
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import styled  from "styled-components";
import { BoundedContent } from "../BoundedContent";
import { BLOGS } from "./blogs";

const Container = styled.div`
height: 100vh;
overflow: auto;
`;

const CompanyName = styled(Link)`
text-decoration: none;
font-size: ${props => props.theme.spacing.large};
font-weight: 500;
color: ${props => props.theme.palette.l1};
`

const Content = styled(BoundedContent)`
display: flex;
flex-direction: column;
gap: ${props => props.theme.spacing.medium};
`;

const Title = styled.h1`
`

const Image = styled.img`
border-radius: 1rem;
width: 70%;
margin-left: auto;
margin-right: auto;
height: 300px;
object-fit: cover;
`


const getFileText = async (filePath: string) => {
  const file = await fetch(filePath)
  return file.text();
}

export function Blog ({ blogId }:{blogId : string }) {
  const [blogText, setBlogText] = useState("");
  const blog = useMemo(() => BLOGS.find(item => item.name === blogId)!, [blogId]);
  useEffect(() => {
    getFileText(blog.filePath).then(setBlogText);
  }, [blog]);

  return <Container>
    <Content>
      <CompanyName to="/">METHODOX</CompanyName>
      <Title>{blog.title}</Title>
      <Image alt={blog.title} src={blog.image}/>
      <div>
        <ReactMarkdown>{blogText}</ReactMarkdown> 
      </div>
    </Content>
  </Container>
}

