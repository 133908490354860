import styled  from "styled-components";
import { Link } from "react-router-dom";
import { IBlog } from "../../Blog/blogs";

const Container = styled.div`
display: flex;
flex-direction: column;
gap: ${props => props.theme.spacing.small};
`

const MyLink = styled(Link)`
color: inherit;
text-decoration: none;
&:hover{
text-decoration: underline;
}
`

const Image = styled.img `
width: 100%;
height: 200px;
border-radius: 1rem;
object-fit: cover;
`

const Title = styled.h3 `
font-size: ${props => props.theme.spacing.medium};
margin: 0;
`

const Description = styled.p `
font-weight: 300;
margin: 0;
`;

export const Blog = ({ image, name, title, description }: IBlog) => {
    return (
        <Container>
            <Image alt={name} src={image}/>
            <Title>
                <MyLink to={`/?blogId=${name}`}>
                    {title}
                </MyLink>
            </Title>
            <Description>{description}</Description>
        </Container>
    )

}


