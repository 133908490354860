import ReactMarkdown from 'react-markdown'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled  from "styled-components";
import { BoundedContent } from "../BoundedContent";

const Container = styled.div`
background-color: #f4f4f4;
height: 100vh;
overflow: auto;
`;

const CompanyName = styled(Link)`
text-decoration: none;
font-size: ${props => props.theme.spacing.large};
font-weight: 500;
color: ${props => props.theme.palette.l1};
`

const Content = styled(BoundedContent)`
display: flex;
flex-direction: column;
gap: ${props => props.theme.spacing.medium};
`;

const Card = styled.div`
background-color: #fff;
border-radius: 2px;
-webkit-box-shadow: 0 8px 14px 3px #0000000d;
box-shadow: 0 8px 14px 3px #0000000d;
padding: ${props => props.theme.spacing.medium};
`;

const getFileText = async (fileName: string) => {
  const filePath = `${process.env.PUBLIC_URL}/jobs/${fileName}.md`;
  const file = await fetch(filePath)
  return file.text();
}

export function Career ({ jobId }:{jobId : string }) {
  const [jobText, setJobText] = useState("");
  useEffect(() => {
    try {
      getFileText(jobId || "").then(setJobText);
    }catch (err) {
    }
  }, [jobId]);

  return <Container>
    <Content>
      <CompanyName to="/">METHODOX</CompanyName>
        
      <Card>
        <ReactMarkdown>{jobText}</ReactMarkdown> 
      </Card>
    </Content>
  </Container>
}
